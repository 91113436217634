export const logoutUser = (expired) => {
  if (expired) {
    localStorage.setItem('mentoriseExpiredLogin', 'true');
  }
  window.localStorage.user = 'false';
};

export const validateLoggedUser = () => {
  let user = false;

  const hasSessionStorage =
    window.sessionStorage.user && window.sessionStorage.user !== 'false';
  const hasLocalStorage =
    window.localStorage.user && window.localStorage.user !== 'false';

  // Convert from session to localStorage
  if (hasSessionStorage && !hasLocalStorage) {
    window.localStorage.setItem('user', window.sessionStorage.getItem('user'));
    window.sessionStorage.user = 'false';
  }

  if (hasLocalStorage) {
    user = JSON.parse(window.localStorage.user);
  }

  if (!user) {
    return false;
  }

  if (!user.roles) {
    logoutUser();
    return false;
  }

  const token = user.authorization;
  const parsed = parseJwt(token);

  if (!parsed || !parsed.exp || loginExpired(parsed.exp)) {
    logoutUser(true);
    return false;
  }

  return user;
};

export const setLoggedUser = (user, authorization) => {
  const payload = { ...user, authorization };
  window.localStorage.user = JSON.stringify(payload);
};

export const updateLoggedUser = (updated, authorization) => {
  if (!authorization) {
    const user = validateLoggedUser();
    authorization = user && user.authorization;
  }

  setLoggedUser(updated, authorization);
};

function parseJwt(token) {
  try {
    const base64Payload = token.split('.')[1];
    const payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
  } catch (err) {
    return false;
  }
}

function loginExpired(exp) {
  // 1 minute
  const padding = 60 * 1000;
  return Date.now() + padding >= exp * 1000;
}
